<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
        class="mt-3"
      >
        <parte-finalizar-form
          v-if="dbReady"
          :id="routeParams.idparte_trabajo"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import ParteFinalizarForm from './components/ParteFinalizarForm'
import Data from './ParteFinalizarData'
import { PARTE_TRABAJO, CHECKLIST, TACTUACION } from '@/utils/consts'
import { get } from 'vuex-pathify'
import { currentDate, currentDateTime } from '@/utils/date'

export default {
  components: {
    ParteFinalizarForm
  },
  mixins: [formPageMixin],
  data () {
    return {
      parteTrabajo: {},
      preguntasNoContestadas: 0,
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = `Finalizar parte Nº${this.routeParams.idparte_trabajo}`
      this.parteTrabajo = await Data.selectParteTrabajo(this, this.routeParams.idparte_trabajo)
      // alerts
      const cantAccionesPendientes = await Data.selectCantAccionesPendientes(this, this.routeParams.idparte_trabajo)
      if (cantAccionesPendientes > 0) {
        this.$alert.showSnackbarWarning(
          `Hay acciones pendientes de realizar (${cantAccionesPendientes})`,
          { persistent: true, actions: [{ text: 'Realizar', flat: true, handler: this.clickIrMaterialAfectado }], }
        )
      }
      if (this.parteTrabajo.checklist_ot.idchecklist_ot) {
        this.preguntasNoContestadas = await Data.selectPreguntasNoContestadas(this, this.parteTrabajo.checklist_ot.idchecklist_ot)
        if (this.preguntasNoContestadas > 0) {
          this.$alert.showSnackbarWarning(
            `Hay preguntas del checklist sin contestar (${this.preguntasNoContestadas})`,
            { persistent: true, actions: [{ text: 'Contestar', flat: true, handler: this.clickIrChecklist }], }
          )
        }
      }
      const tiempoTrabajado = await Data.selectTiempoTrabajado(this, this.routeParams.idparte_trabajo)
      if (tiempoTrabajado === 0) {
        this.$alert.showSnackbarWarning('No has registrado tiempo trabajado', { persistent: true })
      }
      if (this.parteTrabajo.tactuacion.tipo === TACTUACION.tipos.averia) {
        const materialAfectado = await Data.selectCantMaterialAfectado(
          this, this.parteTrabajo.parte_trabajo.idorden_trabajo
        )
        if (materialAfectado === 0) {
          this.$alert.showSnackbarWarning(
            'El parte de avería no contiene material afectado', { persistent: true }
          )
        }
      }
      const selectEmailsSAT = await this.$offline.sistemaTelefono.selectConSAT(this.parteTrabajo.sistema.idsistema)
      if (selectEmailsSAT.length === 0) {
        this.$alert.showSnackbarWarning(
          'Cliente sin dirección de correo electrónico de servicio técnico',
          { persistent: true, actions: [{ text: 'Añadir', flat: true, handler: this.clickIrContactos }] },
        )
      }
    },
    async submitForm () {
      // permiso para dejar checklist pendiente
      if (
        this.parteTrabajo.checklist_ot.estado === CHECKLIST.estados.pendiente &&
        !this.formData.finalizarChecklist &&
        !this.hasViewPerm(this.permissions.partes.finalizarParteConChecklistPendiente)
      ) {
        this.$alert.showSnackbarError('Debes finalizar el checklist para poder finalizar el parte de trabajo')
      } else {
        // permiso para finalizar checklist sin contestar
        if (
          this.formData.finalizarChecklist &&
          this.preguntasNoContestadas > 0 &&
          !this.hasViewPerm(this.permissions.partes.finalizarChecklistSinContestar)
        ) {
          this.$alert.showSnackbarError('Debes contestar todas las preguntas para finalizar el checklist')
        } else {
          if (this.formData.finalizarParte) {
            const fechaFin = currentDateTime()
            await this.$offline.parteTrabajo.finParteTrabajo(
              this.routeParams.idparte_trabajo,
              this.parteTrabajo.orden_trabajo.idorden_trabajo,
              this.formData.comentario_final_tecnico,
              this.formData.notas_internas,
              this.formData.idtcierre_parte,
              this.formData.copiar_comentario_aalbaran,
              this.usuarioIdtecnico,
              PARTE_TRABAJO.estados.finalizado,
              this.formData.dejar_acciones_pendientes,
              fechaFin
            )
            await this.$dirty.modified(this.$dirty.ENTITIES.remote.parteTrabajo, this.routeParams.idparte_trabajo)
          }
          if (this.formData.finalizarChecklist) {
            await this.$offline.checklistOt.finalizarChecklistOt({
              idchecklist_ot: this.parteTrabajo.checklist_ot.idchecklist_ot,
              fchecklist: currentDate(),
              observaciones: this.formData.observaciones_checklist,
              estado: CHECKLIST.estados.finalizado,
            })
          }
          this.$appRouter.replace({
            name: 'offline__parte-resumen-cliente-view',
            params: {
              idparte_trabajo: this.routeParams.idparte_trabajo,
            },
          })
        }
      }
    },
    clickIrChecklist () {
      this.$appRouter.push({
        name: 'offline__checklist-view',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idchecklist_ot: this.parteTrabajo.checklist_ot.idchecklist_ot,
        },
      })
    },
    clickIrMaterialAfectado () {
      this.$appRouter.push({
        name: 'offline__lparte-trabajo-material-afectado-subsis-list',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
        },
      })
    },
    clickIrContactos () {
      this.$appRouter.push({
        name: 'offline__cliente-telefono-list',
        params: {
          idcliente: this.parteTrabajo.sistema.idcliente,
        },
        query: {
          idsistema: this.parteTrabajo.sistema.idsistema,
        },
      })
    },
  },
}
</script>
